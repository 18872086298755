export const API_URL = {
  // auth
  SIGN_IN: "/login",
  SIGN_UP: "/register",
  VERIFY_REGISTRATION_OTP: "/verify-registration-otp",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_OTP: "/verify-otp",
  RESET_PASSWORD: "/reset-password",
  SIGN_OUT: "/auth/logout",
  SEND_LOGIN_OTP: "/send-login-otp",
  LOGIN_WITH_OTP: "/login-with-otp",
  RESEND_OTP: "/send-login-otp",

  // auth profile
  AUTH_PROFILE: "/auth/profile",
  AUTH_AVATAR: "/auth/avatar",
  AUTH_WISHLIST: "/auth/wishlist",

  // auth address
  AUTH_ADDRESS: "/auth/addresses",
  AUTH_PROVINCE: "/auth/addresses/provinces",
  AUTH_DISTRICT: "/auth/addresses/districts",
  AUTH_WARD: "/auth/addresses/wards",
  CONFIG: "/config",
  PAGINATE_PRODUCT: "/products/paginate"
}
