import { API_URL } from "@src/constants"
import fetchServer from "./fetch-server"
import queryString from "query-string"
import { removeNullParams } from "@src/utils/common"

const generateUrl = (endpoint: string): string => {
  return process.env.NEXT_PUBLIC_API_URL + endpoint
}

const getConfig = async () => {
  const response = await fetch(generateUrl(API_URL.CONFIG))
  const result = await response.json()
  return result.data
}

const getProducts = async params => {
  let url = generateUrl(API_URL.PAGINATE_PRODUCT)
  if (params) {
    url = queryString.stringifyUrl({
      url: url,
      query: removeNullParams(params)
    })
  }
  const response = await fetch(url)
  const result = await response.json()
  return result.data
}
export default {
  getConfig,
  getProducts
}
