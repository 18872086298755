"use client";

import { Box, Typography, Button } from "@mui/material";
import { useResponsive } from "@src/hooks";
import useRouterClient from "@src/hooks/use-router-client";
interface Props {
  data;
}
const VoucherNotification = ({
  data
}: Props) => {
  const {
    router
  } = useRouterClient();
  return <Box sx={{
    position: "absolute",
    padding: "16px",
    top: "90%",
    maxWidth: "300px",
    width: "250px",
    left: "0",
    "z-index": "2",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "-25px",
      left: "5%",
      marginLeft: "-5px",
      borderWidth: "15px",
      borderStyle: "solid",
      borderColor: "white transparent transparent transparent",
      transform: "rotate(180deg)",
      filter: 'drop-shadow(0 8px 12px rgba(0, 0, 0, 0.3))'
    },
    "@media (max-width: 1280px)": {
      left: "-160px",
      width: "200px",
      '&::after': {
        left: '83%'
      }
    }
  }} data-sentry-element="Box" data-sentry-component="VoucherNotification" data-sentry-source-file="voucher-notification.tsx">
      <Typography variant="h6" sx={{
      fontWeight: "bold",
      color: "#991C1C",
      marginBottom: "8px"
    }} fontSize={"16px"} textAlign={"center"} data-sentry-element="Typography" data-sentry-source-file="voucher-notification.tsx">
        {data.title}
      </Typography>
      <Typography variant="body2" sx={{
      color: "#991C1C",
      marginBottom: "16px"
    }} fontSize={"14px"} textAlign={"center"} data-sentry-element="Typography" data-sentry-source-file="voucher-notification.tsx">
        {data.message}
      </Typography>
      <Button variant="contained" color="error" sx={{
      textTransform: "none",
      borderRadius: "20px",
      background: "#991C1C",
      margin: "auto"
    }} onClick={() => router.push(data.url)} data-sentry-element="Button" data-sentry-source-file="voucher-notification.tsx">
        Mua Sắm Ngay!
      </Button>
    </Box>;
};
export default VoucherNotification;