export enum VoucherType {
  ORDER = "ORDER",
  FREESHIP = "FREESHIP",
}
export enum VoucherDiscountType {
  AMOUNT = "amount",
  PERCENTAGE = "percentage",
}
export enum PromotionType {
  DISCOUNT = "discount",
  FLASH_SALE = "flash_sale",
  ACCOM_GIFT = "accom_gift",
  ACCOM_PRODUCT = "accom_product",
  COMBO = "combo",
}
export enum EventEmitterChannel {
  CHECKOUT_ERROR = "checkout_error",
  VOUCHER_APPLIED_BY_CODE = "voucher_applied_by_code",
}
export enum OrderStatus {
  ALL = 0,
  WAIT_TO_ACCEPT = 1,
  WAITING_TO_PICK = 2,
  PICKING = 3,
  DELIVERING = 4,
  DELIVERED = 5,
  CANCELED = 6,
  RETURN = 7,
}
export enum PaymentStatus {
  PENDING = 'Pending',
  PAID = 'Paid',
  FAILED = 'Failed',
  REFUND = 'Refund',
  REFUND_FAILED = 'RefundFailed',
}
export enum NotificationType {
  VOUCHER_NOT_USED = 'voucher_not_used',
}